@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Sniglet:wght@800&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: min(calc(100vh / 35), calc(100vw / 35));
}

body {
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e2e2e2;
}

h1 {
  font-family: "Sniglet", cursive;
  font-size: 12rem;
  line-height: 12rem;
  padding: 0;
  margin: 0;
  text-align: center;
  width: 100%;
  color: #d76050;
  text-shadow: 0 0.25rem 0.75rem rgb(255, 164, 176);
}

h1 span {
  color: #509ed7;
  text-shadow: 0.25rem 0.25rem 0.75rem rgb(164, 230, 255);
}

button:focus {
  outline: none;
}

.game {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-gap: 0;
  transform: translate3d(0, -5rem, 0);
}

.status {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.5rem;
  padding: 0.5rem;
}

.status > button {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  font-size: 1rem;
  color: #d76050;
  border: none;
  text-align: right;
  background: transparent;
}

.board {
  display: grid;
  grid-template-columns: 5rem 5rem 5rem;
  grid-template-rows: 5rem 5rem 5rem;
  grid-gap: 0.5rem;
  transform: translate3d(0, 2.5rem, 0) rotate(45deg);
  justify-content: center;
}

.status {
  margin-bottom: 0.5rem;
  color: #757575;
}

.square {
  cursor: pointer;
  font-family: "Sniglet", cursive;
  font-size: 4rem;
  font-weight: 100;
  line-height: 5rem;
  height: 5rem;
  padding: 0;
  text-align: center;
  width: 5rem;
  border: none;
  background: #efefef;
  color: #d76050;
  border-radius: 0.85rem;
  box-shadow: 0.25rem 0.25rem 1rem 0 rgba(0, 0, 0, 0.02);
  text-shadow: 0.25rem 0.25rem 0.75rem rgba(255, 164, 176, 0.75);
}

.disable{
  cursor: not-allowed;
}

.square.O {
  color: #509ed7;
  text-shadow: 0.25rem 0.25rem 0.75rem rgb(164 230 255 / 75%);
}

.game-info {
  margin-left: 1rem;
}

.game-info > button {
  margin-top: 0.5rem;
}
